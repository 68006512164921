<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat>
            <v-card-text>
              <div>
                <ValidationObserver
                  ref="form">
                  <v-form
                    class="text-left">
                    <div
                      style="height: 400px;">
                      <div>
                        <div
                          class="body-1 font-weight-bold black--text text-center">
                          Scan QR Code
                        </div>

                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #e0e0e0;"/>

                        <div
                          class="body-2 text-center mb-6">
                          Klik tombol scan dibawah untuk checkin:
                        </div>
                      </div>

                      <div
                        @click="sheet.scan = true"
                        class="d-flex align-center justify-center cursor-pointer"
                        style="height: 400px; border-radius: 15px; background: #00000040;">
                        <v-avatar
                          tile
                          size="200">
                          <v-img
                            contain
                            alt="utc"
                            :src="require('@/assets/image/home/scan.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </div>

                      <div
                        class="body-2 line-text-second black--text mt-6"
                        style="opacity: 60%;">
                        Untuk mengikuti agenda yang di selenggarakan perusahaan Anda, silahkan klik tombol scan di atas untuk menyatakan Anda telah checkin.
                      </div>
                    </div>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.scan"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.scan = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Scan QR Code
              </span>
            </v-card-title>

            <v-card-text
              class="pa-0">
              <Scan
                @close="sheet.scan = $event" /> -->
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          scan: false
        },

        message: {
          success: '',
          error: ''
        },

        // SEO
        content: {
          url: '/event',
          title: 'Scan QR Agenda',
          description: 'Scan QR Agenda UCT',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Scan: () => import(/* webpackPrefetch: true */ '@/components/profile/scan.vue')
    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>
