<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <v-btn
                to="/home"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img
                    contain
                    alt="utc"
                    :src="require('@/assets/icon/fingerprint.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="15"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Presensi Sekarang
                </span>
              </v-btn>

              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box mt-6"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>
            </v-card-text>

            <no-ssr>
              <swiper
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide
                  v-for="(item, index) in menu"
                  :key="index"
                  class="pt-3"
                  :class="index === 0 ? 'pl-4 pr-3' : index < menu.length - 1 ? 'pr-3' : 'pr-4'"
                  :style="index !== 2 ? 'max-width: 125px;' : 'max-width: 200px;'">
                  <div>
                    <v-btn
                      small
                      rounded
                      :width="index !== 2 ? 100 : 175"
                      elevation="0"
                      @click="to_slide(index)"
                      class="body-2 text-capitalize"
                      :color="selected.menu === index ? set_color : '#eeeeee'"
                      :class="selected.menu === index ? 'white--text font-weight-bold' : 'grey--text'">
                      {{ item.text }}
                    </v-btn>

                    <div v-if="selected.menu === index"
                      class="text-center">
                      <v-icon
                        :color="set_color"
                        size="10">
                        mdi-circle
                      </v-icon>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <v-card-text>
              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.detail = item; sheet.detail = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="
                                item.status === 'hadir' ? 'border-left: 4px solid green;' :
                                item.status === 'ijin' ? 'border-left: 4px solid orange;' : 'border-left: 4px solid red;'">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="caption text-capitalize mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.status === 'leave' ? 'Meninggalkan Jam Kerja' : item.status }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold">
                              {{ item.date | date }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2 line-text-first">
                              <v-divider
                                class="my-2"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

                              <v-row v-if="item.status !== 'ijin' && item.status !== 'cuti'">
                                <v-col
                                  cols="6"
                                  class="py-0">
                                  <div
                                    class="caption">
                                    {{ item.status === 'hadir' ? 'Datang:' : 'Jam Mulai' }}
                                  </div>

                                  <div
                                    class="green--text">
                                    <span v-if="item.status === 'hadir'">
                                      {{ item.start_datetime | datetime }}
                                    </span>

                                    <span v-if="item.status === 'leave'">
                                      {{ item.start_datetime | time }}
                                    </span>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="6"
                                  class="py-0">
                                  <div
                                    class="caption">
                                    {{ item.status === 'hadir' ? 'Pulang:' : 'Jam Selesai' }}
                                  </div>

                                  <div>
                                    <span v-if="item.status === 'hadir'">
                                      <span v-if="item.end_datetime !== ''"
                                        class="red--text">
                                        {{ item.end_datetime | datetime }}
                                      </span>

                                      <span v-else>
                                        -
                                      </span>
                                    </span>

                                    <span v-if="item.status === 'leave'"
                                      class="red--text">
                                      {{ item.end_datetime | time }}
                                    </span>
                                  </div>
                                </v-col>
                              </v-row>

                              <v-row v-if="item.status === 'ijin' || item.status === 'cuti'">
                                <v-col
                                  cols="12"
                                  class="py-0">
                                  <div
                                    class="caption">
                                    Alasan:
                                  </div>

                                  <div>
                                    {{ item.leave_reason !== '' ? item.leave_reason : '-' }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Presensi" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Presensi
              </span>
            </v-card-title>

            <v-card-text
              class="pt-16 mt-3 px-4">
              <div
                class="caption text-capitalize mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>

                <span
                  class="ml-1">
                  {{ selected.detail.status === 'leave' ? 'Meninggalkan Jam Kerja': selected.detail.status  }}
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.detail.date | date }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <v-row v-if="selected.detail.status !== 'ijin' && selected.detail.status !== 'cuti'"
                class="mb-6">
                <v-col
                  cols="6"
                  class="py-0">
                  <div
                    class="caption">
                    {{ selected.detail.status === 'hadir' ? 'Datang:' : 'Jam Mulai' }}
                  </div>

                  <div
                    class="green--text">
                    <span v-if="selected.detail.status === 'hadir'">
                      {{ selected.detail.start_datetime | datetime }}
                    </span>

                    <span v-if="selected.detail.status === 'leave'">
                      {{ selected.detail.start_datetime | time }}
                    </span>
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <div
                    class="caption">
                    {{ selected.detail.status === 'hadir' ? 'Pulang:' : 'Jam Selesai' }}
                  </div>

                  <div>
                    <span v-if="selected.detail.status === 'hadir'">
                      <span v-if="selected.detail.end_datetime !== ''"
                        class="red--text">
                        {{ selected.detail.end_datetime | datetime }}
                      </span>

                      <span v-else>
                        -
                      </span>
                    </span>

                    <span v-if="selected.detail.status === 'leave'"
                      class="red--text">
                      {{ selected.detail.end_datetime | time }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <div v-if="selected.detail.status !== 'hadir'"
                class="body-2 mb-4">
                <div
                  class="black--text font-weight-bold mb-2">
                  Alasan:
                </div>

                <div>
                  {{ selected.detail.leave_reason !== '' ? selected.detail.leave_reason : '-' }}
                </div>
              </div>

              <div v-if="selected.detail.status === 'hadir'"
                class="body-2 font-weight-bold black--text mb-4">
                Lokasi:
              </div>

              <div v-if="selected.detail.status === 'hadir'"
                class="mb-6">
                <gmap-map
                  ref="gmap"
                  :center="center"
                  :zoom="15"
                  :scrollwheel="false"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  style="width:100%; height: 30vh;">
                  <gmap-marker
                    :position="currentPosition.latlng"
                    :icon="currentPosition.icon">
                  </gmap-marker>
                </gmap-map>
              </div>

              <div v-if="selected.detail.status === 'hadir'"
                class="body-2 font-weight-bold black--text mb-2">
                Jarak:
              </div>

              <v-row v-if="selected.detail.status === 'hadir'">
                <v-col
                  cols="6"
                  class="pt-0">
                  <div
                    class="caption">
                    Datang:
                  </div>

                  <div>
                    <span v-if="selected.detail.end_range !== ''">
                      <strong>{{ selected.detail.end_range }}</strong> Meter
                    </span>

                    <span v-else>
                      -
                    </span>
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="pt-0">
                  <div
                    class="caption">
                    Pulang:
                  </div>

                  <div>
                    <span v-if="selected.detail.end_range !== ''">
                      <strong>{{ selected.detail.end_range }}</strong> Meter
                    </span>

                    <span v-else>
                      -
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="selected.detail.status === 'hadir'">
                <v-col
                  cols="6"
                  class="pt-0">
                  <div
                    class="caption mb-1">
                    Gambar Datang:
                  </div>

                  <v-img v-if="selected.detail.start_image_url !== ''"
                    contain
                    alt="utc"
                    width="100%"
                    height="auto"
                    :src="selected.detail.start_image_url"
                    class="ma-auto border-radius">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div v-else>
                    <strong>-</strong>
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="pt-0">
                  <div
                    class="caption mb-1">
                    Gambar Pulang:
                  </div>

                  <v-img v-if="selected.detail.end_image_url !== ''"
                    contain
                    alt="utc"
                    width="100%"
                    height="auto"
                    :src="selected.detail.end_image_url"
                    class="ma-auto border-radius">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div v-else>
                    <strong>-</strong>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          detail: false
        },

        selected: {
          detail: {},

          menu: 0
        },

        menu: [
          {
            text: 'Hadir',
            value: 'hadir'
          },
          {
            text: 'Izin',
            value: 'ijin'
          },
          {
            text: 'Meninggalkan Jam Kerja',
            value: 'leave'
          },
          {
            text: 'Cuti',
            value: 'cuti'
          }
        ],

        search: '',

        list: [],
        pagination: {},
        limit: 10,
        center: {
          lat: null,
          lng: null
        },
        currentPosition: {},

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/attendance',
          title: 'Presensi',
          description: 'Presensi UCT',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'selected.detail': function(val) {
        this.$gmapApiPromiseLazy().then(() => {
          this.center = {
            lat: parseFloat(val.start_latitude),
            lng: parseFloat(val.start_longitude)
          }

          this.currentPosition = {
            latlng: {
              lat: parseFloat(val.start_latitude),
              lng: parseFloat(val.start_longitude)
            },
            icon: {
              url: require('@/assets/icon/location_pin.png'),
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(25, 50)
            }
          }
        })
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      to_slide (val) {
        this.selected.menu = val

        this.$refs.swiper.$swiper.slideTo(this.selected.menu, 500, false)

        this.fetch()
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search,

          status: this.menu[this.selected.menu].value
        }

        await this.$axios.$get(`${process.env.API_URL}user/attendance/history`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      }
    }
  }
</script>
