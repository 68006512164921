<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.delete"
          persistent
          width="400">
          <v-card
            style="border-radius: 30px;">
            <v-card-text
              class="pt-2 pb-6">
              <v-img
                width="150"
                height="150"
                alt="utc"
                :src="require('@/assets/icon/info_hapus.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div v-if="Object.keys(selected.doc).length > 0"
                class="body-1 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus
                </div>

                <div>
                  data <strong>Dokumen</strong> yang Anda pilih?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #EBEBEB;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="deletes()"
                :loading="process.form"
                :disabled="process.form"

                rounded
                color="#348d37"

                class="font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn>

              <v-btn
                @click="dialog.delete = false"
                :disabled="process.form"

                rounded
                color="red"
                outlined

                class="font-weight-bold text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.error">
          <v-card
            style="border-radius: 30px;">
            <v-card-text
              class="body-2 text-first line-text-second pt-2 pb-6">
              <v-img
                width="150"
                height="150"
                alt="utc"
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <!-- <v-card-text
              class="pb-1">
              <v-btn
                @click="sheet.add_doc = true; reset(); fetchType();"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img
                    contain
                    alt="utc"
                    :src="require('@/assets/icon/add.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="15"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Tambah Dokumen Perusahaan
                </span>
              </v-btn>
            </v-card-text> -->

            <v-card-text
              class="pt-2">
              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.doc = item; sheet.detail_doc = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.create_datetime | date }} Pukul {{ item.create_datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc !== '' ? item.desc : '-' }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <Empty v-if="!process.load && list.length < 1"
                margin="my-16 py-16"
                size="125"
                message="Dokumen Perusahaan" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_doc"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.add_doc = false; reset();"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                {{ form.doc_id === '' ? 'Tambah' : 'Ubah' }} Dokumen
              </span>
            </v-card-title>

            <v-card-text
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver
                  ref="form">
                  <v-form>
                    <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tipe Dokumen
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Memo Internal"

                        :items="type"
                        item-text="title"
                        item-value="id"
                        :item-color="set_color"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.doc_type_id"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <div
                      class="mb-6">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Upload Dokumen
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <uploadfolder
                        v-model="upload.folder"
                        style="height: 250px;">
                        <div
                          slot="activator"
                          class="cursor-pointer">
                          <v-card
                            flat
                            height="250"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea;
                                  border-style: dashed;">
                            <div v-if="form.url.length < 1">
                              <v-icon v-if="!process.image"
                                size="65"
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>

                              <v-progress-circular v-else
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </div>

                            <v-img v-else
                              contain
                              height="250"
                              :src="form.url"
                              :alt="form.url"
                              class="text-center d-flex align-center">
                              <div
                                class="d-flex aling-center justify-center"
                                style="width: 100%; height: auto;">
                                <v-icon v-if="!process.image"
                                  size="65"
                                  color="white">
                                  mdi-cloud-upload-outline
                                </v-icon>

                                <v-progress-circular v-else
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </div>
                            </v-img>
                          </v-card>
                        </div>
                      </uploadfolder>
                    </div>

                    <ValidationProvider
                      name="desc"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Keterangan
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save()"
                        class="no_box_area"
                        solo
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."

                        :append-icon="valid && form.desc !== '' ? 'mdi-check-circle-outline' : ''"

                        v-model="form.desc"
                        :success="valid && form.desc !== ''"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row
                  align="center">
                  <v-col
                    cols="3"
                    class="pb-0">
                    <v-img
                      contain
                      alt="utc"
                      :src="require('@/assets/icon/document.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col
                    cols="9"
                    class="black--text pb-0">
                    <div>
                      <div
                        class="body-2 line-text-second black--text"
                        style="opacity: 60%;">
                        Silahkan isi dokumen pribadi untuk kelengkapan data diri Anda, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-4">
                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="sheet.add_doc = false; reset();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined

                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_doc"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail_doc = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Dokumen
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.doc).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>

                <span
                  class="ml-1">
                  {{ selected.doc.create_datetime | date }} Pukul {{ selected.doc.create_datetime | time }}
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.doc.title }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.doc.desc !== '' ? selected.doc.desc : '-' }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Dokumen:
              </div>

              <div v-if="selected.doc.url.split('.').pop() !== 'pdf'">
                <v-img
                  contain
                  alt="utc"
                  width="100%"
                  height="auto"
                  :src="selected.doc.url"
                  class="ma-auto border-radius">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div v-else>
                <iframe
                  :src="`${selected.doc.url}#toolbar=0`"
                  allowfullscreen
                  frameborder="0"
                  width="100%"
                  height="397"
                  style="border-radius: 15px; border: 2px solid #323639;">
                </iframe>
              </div>
            </v-card-text>

            <!-- <v-card
              flat
              color="#FFFFFF"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="sheet.add_doc = true; reset(selected.doc); fetchType();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="blue"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-circle-edit-outline</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Ubah
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="dialog.delete = true"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-delete-circle-outline</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Hapus
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card> -->
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          delete: false,
          error: false
        },

        sheet: {
          add_doc: false,
          detail_doc: false
        },

        selected: {
          doc: {}
        },

        list: [],
        type: [],
        pagination: {},

        upload: {
          folder: null
        },

        form: {
          doc_id: '',
          user_id: '',
          doc_type_id: '',
          title: '',
          desc: '',
          url: ''
        },

        process: {
          load: false,
          type: false,
          form: false,
          image: false
        },
        message: {
          success: '',
          error: ''
        },

        // SEO
        content: {
          url: '/doccompany',
          title: 'Dokumen Perusahaan',
          description: 'Dokumen Perusahaan UCT',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD DOC

              this.form.url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'form.doc_type_id': function(val){
        this.type.map(obj => {
          if (val === obj.id) {
            this.form.title = obj.title
          }
        })
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          // user_id: this.user.id,
          // unit_id: this.user.unit_id
        }

        await this.$axios.$get(`${process.env.API_URL}admin/rules/data_pegawai`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchType () {
        this.process.type = true

        let params = {
          // cat: 'Perusahaan'
          'cat[neq]': 'Pegawai',
          unit_id: this.user.role !== 'superadmin' ? this.user.unit_id : ''
        }

        await this.$axios.$get(`${process.env.API_URL}admin/doc_type`, { params })
        .then((response) => {
          this.process.type = false

          if (response.status === 200) {
            this.type = response.results.data
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          doc_id: item !== undefined ? item.id : '',
          user_id: this.user.id,
          doc_type_id: item !== undefined ? item.doc_type_id : '',
          title: item !== undefined ? item.title : '',
          desc: item !== undefined ? item.desc : '',
          url: item !== undefined ? item.url : ''
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.doc_id === '' ? 'admin/rules/add' : 'admin/rules/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}${url}`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.add_doc = false

              this.sheet.detail_doc = false

              this.fetch()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async deletes () {
        this.message.error = ''

        this.process.form = true

        await this.$axios.$post(`${process.env.API_URL}admin/rules/delete`, {
          id: this.selected.doc.id
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {

            this.sheet.detail_doc = false

            this.dialog.delete = false

            this.fetch()

          } else {
            this.dialog.error = true
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
